<div class="layout">
  <owc-header #headerElement>
    <owc-header-row
      seperator
      elevation
      variant="primary"
      class="primary-slot main-header"
    >
      <ng-container *ngIf="isLoading | async; else orgName">
        <owc-skeleton-loader />
      </ng-container>

      <ng-template #orgName>
        <div>{{ orgDisplayName }}</div>
      </ng-template>

      <div class="right-section">
        <owc-typography
          class="owc-typography owc-typography--variant-badge owc-typography--device-desktop pn"
          variant="badge"
          device="desktop"
        >
          {{ profile.displayName }}
        </owc-typography>
        <div class="pr">
          <owc-icon-button
            class="icon-button mr10"
            icon="user"
            id="profileMenuHover"
          ></owc-icon-button>
          <owc-tooltip
            anchor="profileMenuHover"
            placement="bottom"
            class="toolTip"
          >
            {{ i18n.LBL_MA }}
          </owc-tooltip>

          <owc-popover
            anchor="profileMenuHover"
            trigger="click"
            position="right"
            class="profileMenu"
          >
            <div class="wrap">
              <div slot="header" class="header">
                <owc-avatar class="ttc">{{ getShortName() }}</owc-avatar>
                <owc-typography variant="subtitle1">{{
                  profile.displayName
                }}</owc-typography>
                <owc-typography variant="body2">{{
                  profile.username
                }}</owc-typography>
              </div>
              <div class="body">
                <owc-divider class="mb-0"></owc-divider>
                <owc-list class="owcList">
                  <owc-list-item [disabled]="true">
                    {{ i18n.LBL_MS }}
                  </owc-list-item>
                </owc-list>
                <owc-list class="owcList" *ngIf="showResetPwd">
                  <owc-list-item (click)="reset()">
                    {{ i18n.LINKS.PR }}
                  </owc-list-item>
                </owc-list>
                <div class="logBtn">
                  <owc-button
                    variant="secondary"
                    class="m16"
                    routerLink="/logout"
                  >
                    {{ i18n.BTN_LOGOUT }}
                  </owc-button>
                </div>
              </div>
            </div>
          </owc-popover>
        </div>

        <div class="pr">
          <owc-icon-button
            class="icon-button mr10"
            icon="notification"
            id="tt_notification"
            [disabled]="true"
          >
          </owc-icon-button>
          <owc-tooltip anchor="tt_notification" placement="top">
            {{ i18n.LBL_NS }}
          </owc-tooltip>
        </div>

        <owc-icon-button
          class="icon-button mr10"
          icon="circle_help"
          id="tt_help"
          [disabled]="true"
        ></owc-icon-button>
        <owc-tooltip anchor="tt_help" placement="top">
          {{ i18n.LBL_HELP }}
        </owc-tooltip>
        <owc-logo class="brand-logo" name="roche_blue" size="mini"></owc-logo>
      </div>
    </owc-header-row>
  </owc-header>

  <div class="content">
    <owc-drawer
      [visible]="true"
      mode="push"
      [backdropProps]="backdropProps"
      hide-backdrop="true"
      placement="left"
      class="owc-drawer owc-drawer--mode-push owc-drawer--placement-left owc-drawer--visible"
      role="complementary"
    >
      <div
        class="panel_wrapper"
        slot="panel"
        [class]="leftMenuVisibility ? 'menuExpMode' : 'menuColMode'"
      >
        <owc-side-menu #sideMenuElement mode="push">
          <owc-component-header slot="header" separator>
            <owc-icon-button
              class="icon-button btn_menu"
              [icon]="leftMenuVisibility ? 'clear' : 'menu'"
              (click)="handleMenuButtonClick()"
            ></owc-icon-button>
          </owc-component-header>

          <owc-list
            no-border
            (selectedChange)="onSelectedChange($event)"
            [selected]="selectedMenu"
          >
            <div *ngFor="let item of menuItems; let i = index">
              <owc-list-item
                value="{{ item.name }}"
                [routerLink]="item.url"
                id="{{ i + 1 }}"
                *ngIf="rolePermission(item.role)"
              >
                <owc-icon
                  slot="prefix"
                  family="outlined"
                  [name]="item.icon"
                ></owc-icon>
                {{ item.name }}
              </owc-list-item>
              <owc-tooltip
                [anchor]="i + 1"
                placement="right"
                [hidden]="isToolTopShow"
              >
                {{ item.name }}
              </owc-tooltip>
            </div>
          </owc-list>
          <owc-list no-border class="bottom-list">
            <div>
              <owc-list-item (click)="openDiscourse()">
                <owc-icon
                  slot="prefix"
                  family="outlined"
                  name="chat"
                ></owc-icon>
                {{ "Discourse" }}
              </owc-list-item>
            </div>
          </owc-list>
        </owc-side-menu>
      </div>

      <div class="content_wrapper">
        <div class="container">
          <router-outlet></router-outlet>
        </div>
      </div>
    </owc-drawer>

    <owc-footer #footerElement fix-bottom variant="global" class="footer">
      <div slot="bottom">
        <owc-typography #typographyElement>
          © 2023 F. Hoffmann-La Roche Ltd
        </owc-typography>
      </div>
    </owc-footer>
  </div>
</div>

<owc-modal-dialog
  #modalIdleSession
  disable-backdrop-click="true"
  class="sessionModal"
>
  <owc-typography variant="body1" element="p">
    {{ i18n.SESSION_IDLE_DESC1 }} {{ timeRemaining$ | async | date : "s" }}
    {{ i18n.SESSION_DESC2 }}
  </owc-typography>
  <owc-component-header slot="header">
    <owc-typography variant="title5" element="h5">{{
      i18n.SESSION_IA
    }}</owc-typography>
  </owc-component-header>

  <owc-component-footer slot="footer">
    <owc-button slot="suffix" (click)="onNoClick()" variant="secondary">
      {{ i18n.BTN_NO }}</owc-button
    >
    <owc-button (click)="onYesClick()" slot="suffix">
      {{ i18n.BTN_YES }}</owc-button
    >
  </owc-component-footer>
</owc-modal-dialog>
