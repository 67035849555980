// @ts-nocheck
import { ActivatedRoute, Router, ActivationEnd } from '@angular/router';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Menu } from '../../common/models/Menu';

import { Components } from '@one/web-components';
import { StorageService } from '../../services/storage.service';
import { Permissions, Profile } from '../../interfaces/common.interface';
import { i18n } from '../../i18n.en';
import { NavItems, NAV } from '../../core/nav';
import { NavifyauthService } from '../../services/navifyauth.service';
import { map, timer, takeWhile, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { OrganizationManagementService } from "../../services/organizationManagement.service";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainComponent implements OnInit {
  selectedMenu: (string | number)[] = []; // By default Landing page will be selected
  @ViewChild('headerElement', { static: true }) headerElement!: Components.OwcHeader;
  @ViewChild('listElement', { static: true }) listElement!: Components.OwcList;
  @ViewChild('buttonElement', { static: true })
  buttonElement!: Components.OwcButton;
  profile!: Profile;
  isToolTopShow: boolean = true;
  @ViewChild('sideMenuElement', { static: true }) sideMenuElement!: Components.OwcSideMenu;
  @ViewChild('footerElement', { static: true }) footerElement!: Components.OwcFooter;
  @ViewChild('indicatorBadgeElement', { static: true }) indicatorBadgeElement!: Components.OwcIndicatorBadge;
  @ViewChild('modalIdleSession', { static: true }) modalIdleSession!: Components.OwcModalDialog;
  readonly Menu = Menu;
  backdropProps = { onBackdropClick: this.handleDrawerVisibility.bind(this) };

  leftMenuVisibility = true;
  userMenuVisibility = false;
  i18n = i18n;
  menuItems: NavItems[] = NAV;
  isLoading = new BehaviorSubject<boolean>(false);
  currentUserPermissions!: Permissions;
  @ViewChild('profileMenuHover', { static: true }) profileMenuHover!: Components.OwcPopover;
  showResetPwd: boolean = false;
  timeRemaining$;
  discourseUrl: string = "";
  orgDisplayName: string = "";

  constructor(
    readonly router: Router,
    readonly storageService: StorageService,
    public _route: ActivatedRoute,
    public http: HttpClient,
    readonly navifyServ: NavifyauthService,
    readonly organizationManagementService: OrganizationManagementService) {
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd && event.snapshot.data['selected_menu']) {
        this.selectedMenu = event.snapshot.data['selected_menu'].length > 0 ? event.snapshot.data['selected_menu'] : 'Dashboard';
      }
    });
  }

  ngOnInit(): void {
    this.currentUserPermissions = this.storageService.secureStorage.getItem('currentUserPermissions');
    this.profile = this.storageService.secureStorage.getItem('profile');
    const email = this.profile.username?.toLowerCase().search('roche.com');
    if (email === -1) {
      this.showResetPwd = true;
    } else {
      this.showResetPwd = false;
    }
    this.initIdle();
    this.initAuth();
    this.navifyServ.$isIdle.subscribe({
      next: (res) => {
        if (res === true) {
          this.isIdleModal();
        }
      },
      error: (err) => { },
      complete: () => { }
    });
    this.getDiscourseLink();
    this.getOrganizationDisplayName();
  }

  getOrganizationDisplayName(): void {
    this.isLoading.next(true);
    this.organizationManagementService.getOrganization().subscribe(data => {
      this.orgDisplayName = data.result.orgDisplayName
      this.isLoading.next(false);
    });
  }

  getDiscourseLink(): void {
    this.organizationManagementService.getDiscourseURL().subscribe(data => {
      this.discourseUrl = data.result.discourseUrl
    });
  }

  openDiscourse() {
    const link = document.createElement('a');
    link.href = this.discourseUrl
    link.target = '_blank';
    link.click()
  }
  async initIdle() {
    await this.navifyServ.initiateIdle();
  }

  async initAuth() {
    await this.navifyServ.getLoginResults();
  }

  isIdleModal() {
    this.modalIdleSession.visible = true;
    this.timeRemaining$ = timer(0, Number('1000')).pipe(
      map((n) => (Number('60') - n) * Number('1000')),
      takeWhile((n) => n >= 0)
    );
    setInterval(() => {
      this.onNoClick()
    }, Number('60000'));
  }

  async onNoClick() {
    await this.navifyServ.confirmIdleAction('idle_no');
    this.modalIdleSession.visible = false;
  }

  async onYesClick() {
    await this.navifyServ.confirmIdleAction('idle_yes');
    this.modalIdleSession.visible = false;
  }

  rolePermission(role: string) {

    let a = false;
    for (const key in this.currentUserPermissions) {
      if (key === role) {
        this.currentUserPermissions[role] === true ? a = true : a = false;
      }
    }
    return a;
  }

  onSelectedChange(e: CustomEvent<(string | number)[]>) {
    this.selectedMenu = e.detail;
  }

  handleDrawerVisibility(): void {
    this.leftMenuVisibility = !this.leftMenuVisibility;
    this.isToolTopShow = !this.isToolTopShow;
  }

  handleMenuButtonClick(): void {
    this.leftMenuVisibility = !this.leftMenuVisibility;
    this.isToolTopShow = !this.isToolTopShow;
  }

  toggleExpandableGroup(status: boolean): void {
    const menuTabMapper: Partial<
      Record<
        Menu,
        {
          setAllState: (status: boolean) => void;
        }
      >
    > = {};
  }

  getShortName() {

    return this.profile.displayName?.split(' ').map((n: string) => n[0]).join('');
  }

  reset() {
    this.navifyServ.resetPwd(this.profile.username);
  }

  logout() {
    this.profile.displayName = '';
  }

  protected readonly open = open;
}
